import axios from "axios";
import {RegistrationDate, RegistrationForm} from "@/models/RegistrationForm";
import {UserData} from "@/models/UserData";

export default {

  getUrl(): string {
    return axios.defaults.baseURL || "";
  },

  getDateStatus(regDate: RegistrationDate): "BookedOut" | "Past" | "Active" {
    if ((regDate.start - regDate.lastRegistration * 60 * 1000) < new Date().getTime()) return "Past";
    if (regDate.userLimit === -1 || this.getDateAvailableSeats(regDate) > 0) return "Active"
    return "BookedOut";
  },

  getDateAvailableSeats(regDate: RegistrationDate): number {
    return regDate.userLimit - regDate.countRegisteredUser;
  },

  getRegistrationForm(url: string): Promise<RegistrationForm> {
    return axios.get('/registering/form', { params: { "url": url }}).then(response => response.data);
  },

  sendUserData(userData: UserData, regFormId: number): Promise<UserData> {
    return axios.post('/registering/userData/' + regFormId, userData).then(response => response.data);
  },

  getUserData(regFormId: number, key: string): Promise<UserData> {
    return axios.get(`/registering/userData/${regFormId}/${key}`).then(response => response.data);
  },

  updateUserData(regFormId: number, key: string, userData: UserData): Promise<UserData> {
    return axios.put(`/registering/userData/${regFormId}/${key}`, userData).then(response => response.data);
  },

  deleteUserData(regFormId: number, key: string): Promise<void> {
    return axios.delete(`/registering/userData/${regFormId}/${key}`).then();
  },

  confirmDoubleOptIn(regFormId: number, key: string): Promise<void> {
    return axios.post(`/registering/doi/${regFormId}/${key}/`).then(response => response.data);
  },

  sortCustomFields<T extends { global: boolean, index: number }>(fields: T[]): T[] {
        return fields.sort((a, b) => {
            if (a.global && !b.global) {
                return -1;
            } else if (!a.global && b.global) {
                return 1;
            } else {
                // Wenn beide global oder beide nicht global sind, sortiere nach dem Index
                return a.index - b.index;
            }
        });
    }

}