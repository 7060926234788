












































import {Component, Vue} from 'vue-property-decorator';
import Message from './components/Message.vue';
import RegistrationService from '@/service/RegistrationService';
import {RegistrationForm} from "@/models/RegistrationForm";
import Loading from "@/pages/Loading.vue";
import Imprint from "@/pages/Imprint.vue";
import {default as RegFooter} from "@/components/Footer.vue";
import {Page} from "@/store/form";
import RegForm from "@/pages/RegForm.vue";
import Result from "@/pages/Result.vue";
import Deleted from "@/pages/Deleted.vue";
import {AxiosError} from "axios";
import {UserData} from "@/models/UserData";

@Component({
  components: {
    Deleted,
    Result,
    RegForm,
    RegFooter,
    Imprint,
    Loading,
    Message
  },
})
export default class App extends Vue {

  errorMessage = "";
  loading = false;


  get isError(): boolean {
    return (
        this.page === 'already_registered' ||
        this.page === 'error' ||
        this.page === 'banned'
    )
  }

  mounted(): void {
    if (window.location.pathname === "" || window.location.pathname === "/") {
      this.setPage("start");
    } else {
      const url = window.location.pathname;
      RegistrationService.getRegistrationForm(url)
          .then(form => {
            this.setBranding(form);
            this.setForm(form);
            this.setPage("form");

            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has('confirmation') && this.form) {
              this.handleDoiConfirmation(urlParams);
            }
            // Load user data if key in query params found
            if (urlParams.has('key') && this.form) {
              this.handleEditRegistration(urlParams);
            } else {
              const userData = this.handleUrlParams(urlParams, form);
              this.$store.commit('setUserData', userData);
            }
          }).catch(e => this.showError(e));
    }
  }

private handleUrlParams(urlParams: URLSearchParams, form: RegistrationForm): { userData: UserData, unhandledParams: { key: string, value: string }[] } {
  // Read initial form values from url params
  const keys = ["firstname", "lastname", "email", "phone", "company", "comment", "partnerId", "addressStreet", "addressCity", "vvId", "salutation"];
  const userData = this.$store.getters.userData;
  const handledKeys: { [key: string]: boolean } = {};

  keys.forEach(key => {
    if (!urlParams.has(key)) return;
    userData[key] = urlParams.get(key);
    handledKeys[key] = true;
  });

  if (urlParams.has("id")) {
    userData["partnerId"] = urlParams.get("id"); // Map urlparam id to partnerId
    handledKeys["id"] = true;
  }

  if (form.fields) {
    form.fields.forEach(field => {
      if (!urlParams.has(field.title)) return;
      userData.values.push({
        fieldId: field.id,
        value: urlParams.get(field.title)
      });
      handledKeys[field.title] = true;
    });
  }

  if (form.customFields) {
    form.customFields.forEach(field => {
      if (!urlParams.has(field.title)) return;
      userData.customValues.push({
        fieldId: field.id,
        value: urlParams.get(field.title)
      });
      handledKeys[field.title] = true;
    });
  }

  const unhandledParams: { key: string, value: string }[] = [];
  urlParams.forEach((value, key) => {
    if (!handledKeys[key]) unhandledParams.push({key, value});
  });

  userData.customUrlParams = unhandledParams;
  return userData;
}

  private handleEditRegistration(urlParams: URLSearchParams) {
    this.$store.commit('setKey', urlParams.get("key"));
    if (this.key !== undefined && this.form) {
      RegistrationService.getUserData(this.form.id, this.key)
          .then(userData => this.$store.commit('setUserData', userData))
          .catch(e => this.showError(e));
    }
  }

  private handleDoiConfirmation(urlParams: URLSearchParams) {
    this.$store.commit('setKey', urlParams.get("confirmation"));
    if (this.key !== undefined && this.form) {
      RegistrationService.confirmDoubleOptIn(this.form.id, this.key)
          .then(userData => {
            this.$store.commit('setUserData', userData);
            this.setPage('confirmed');
          })
          .catch(e => this.showError(e));
    }
  }

  showError(error: AxiosError) {
    if (error.response?.status === 404) {
      this.setPage("notfound");
    } else {
      this.setPage("error");
      this.errorMessage = error.message;
    }
  }

  setBranding(form: RegistrationForm): void {
    if (form.branding === undefined) return;


    const r: any = document.querySelector(':root');
    if (r !== null) {
      const brandingData: any = JSON.parse(form.branding.data);
      if (brandingData.primaryBgColor) {
        r.style.setProperty('--primary-bg', brandingData.primaryBgColor);
        r.style.setProperty('--primary-bg-darker', this.lightenDarkenColor(brandingData.primaryBgColor, -20));
      }
      if (brandingData.primaryTextColor) r.style.setProperty('--primary-text', brandingData.primaryTextColor);
      if (brandingData.headerBgColor) r.style.setProperty('--header-bg', brandingData.headerBgColor);
      if (brandingData.headerTextColor) r.style.setProperty('--header-text', brandingData.headerTextColor);
      if (brandingData.logoPosition === "left") this.$store.commit('setLogoPosition', "left")
      if (brandingData.logoPosition === "middle") this.$store.commit('setLogoPosition', "middle")
      if (brandingData.logoPosition === "right") this.$store.commit('setLogoPosition', "right")
      this.$store.commit('setFooterEnabled', form.branding.footerEnabled || false);
      this.$store.commit('setFooter', form.branding.footer || "");
      this.$store.commit('setPrivacy', form.branding.privacy || "");
    }
  }

  lightenDarkenColor(col: string, amt: number): string {
    // from: https://css-tricks.com/snippets/javascript/lighten-darken-color/
    let usePound = col.startsWith("#");
    const num = parseInt(usePound ? col.slice(1) : col, 16);
    let r = this.clamp((num >> 16) + amt, 0, 255);
    let b = this.clamp(((num >> 8) & 0x00FF) + amt, 0, 255);
    let g = this.clamp((num & 0x0000FF) + amt, 0, 255);
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16).padStart(6, "0");
  }

  clamp = (num: number, min: number, max: number): number => {
    return Math.min(Math.max(num, min), max);
  }

  setForm(form: RegistrationForm): void {
    this.$store.commit('setForm', form);
    this.$i18n.locale = form.language.toLowerCase(); // 'de' or 'en'
    document.title = form.title;
  }

  get form(): RegistrationForm | undefined {
    return this.$store.getters.form;
  }

  get isDoiConference(): boolean {
    if (this.userData.dateDoi) return false;
    if (!this.form || !this.form.doi) return false;
    return this.form.doi.type === "CONFERENCE";
  }

  get page(): Page {
    return this.$store.getters.page;
  }

  get userData(): UserData {
    return this.$store.getters.userData;
  }

  get lastPage(): Page {
    console.log(this.$store.getters.lastPage)
    return this.$store.getters.lastPage;
  }

  get key(): string | undefined {
    return this.$store.getters.key;
  }

  setPage(page: Page): void {
    this.$store.commit('setLastPage', this.page);
    this.$store.commit('setPage', page);
  }

  edit(): void {
    this.setPage('form')
  }

}
